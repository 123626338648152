.square{
    width:90%;
    display: flex;
    min-height: 300px;
    flex-direction: column;   
    justify-content: space-between;
    transform: rotate(-8.82deg);
    position: absolute;
    top: 160px;
    z-index:-1;
}
*{
    box-sizing: border-box;
}


.square span:nth-child(odd){
    background: #DCE2EE;
    width:10px;
    height:10px;
    border-radius: 5px;
    animation: 1s colorize infinite;
}
.square span:nth-child(even){
    background: #DCE2EE;
    width:10px;
    height:10px;
    border-radius: 5px;
    animation: 2s colorize infinite;
}

@keyframes colorize{
    0%{
        background: red;
    }
    50%{
        background: lime;
    }
    100%{
        background: red;
    }
}