@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

*{
    box-sizing: border-box;
}




section{
    padding: 40px 0px;
}

section p.trust{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 160%;
}

section h3 span{
    color: #F48C06;
}
section h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 180%;
    color: #1f0c86;
    margin-top: 10px;
    text-align: center;
}
section h3.w500 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 180%;
    color: #1f0c86;
    margin-top: 0;
}
section h3.w600 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 180%;
    color: #1f0c86;
    margin-top: 10px;
    text-align: start;
}

section p{
    display: block;
    max-width: 837px;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 180%;
    text-align: center;
    color: #696984;
}
section p.text-start{
    display: block;
    max-width: 837px;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 180%;
    text-align: start;
    color: #696984;
}
section p.text-start20{
    display: block;
    max-width: 837px;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    text-align: start;
    color: #696984;
}
section p.what{
    display: block;
    max-width: 1101px;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 180%;
    text-align: center;
    color: #696984;
}
section p.billing{
    display: block;
    max-width: 837px;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    text-align: center;
    color: #696984;
    margin-top:20px;
}

section .sec_blok {
    width: 100%;
    min-height: 430px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 10px 60px rgba(38, 45, 118, 0.08);
    border-radius: 20px;
    padding: 15px 15px;
    margin-top:150px;
    transition:0.5s;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

}
section .sec_blok:hover{
    transform: scale(1.05);
    transition:0.5s;
}
section .sec_blok:hover .icon_billing, section .sec_blok:hover .icon_calendar, section .sec_blok:hover .icon_people{
    animation: 1s jumping normal;
}
@keyframes jumping{
    0%{
        transform:rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
    }
}

section h4{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: #2F327D;
    margin:0;

}



section .icon_calendar, section .icon_billing, section .icon_people{
    width: 100px;
    height: 100px;
    position: absolute;
    top:-50px;
    left:calc(100%/2)-50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:50px;
}

section .icon_billing{
    
    background: #5B72EE;
    box-shadow: 0px 10px 40px rgba(54, 61, 136, 0.06);
    
}
section .icon_calendar{
    
    background: #F48C06;
    box-shadow: 0px 16px 40px rgba(37, 44, 113, 0.1);
    
}
section .icon_people{
    
    background: #29B9E7;
    box-shadow: 0px 10px 40px rgba(54, 61, 136, 0.06);
    
}


section .introduction{
    width: 95%;
    height: 400px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 20px;
    position:relative;
    margin:100px 0px;
}

section .introduction h1{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;
    z-index:1;
}

section .introduction .parda{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    opacity: 0;
    background: rgba(23, 27, 65, 0.5);
    transition: 0.5s;
}
section .introduction img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
}
section .introduction:hover .parda {
    opacity: 1;
    transition: 0.5s;
}

section .introduction button.intro{
    width: 283px;
    height: 80px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 40px;
    background: rgba(0, 0, 0, 0);
    font-family: 'Poppins' , sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    z-index:1;
    color: white;
}
section .introduction button.intro:hover{
    background: rgba(35, 189, 238, 0.9);
}


@keyframes circle10{
    0%{
        margin-top: 0;
    }
    50%{
        margin-top:20px;
    }
    100%{
        margin-top:0;
    }
}
@keyframes circle5{
    0%{
        margin-left: 0;
    }
    50%{
        margin-left:20px;
    }
    100%{
        margin-left:0;
    }
}
@keyframes rotate{
    0%{
        transform:rotate(0deg);
    }
    
    100%{
        transform:rotate(180deg);
    }
}

.circle10{
    width: 73px;
    height:73px;
    background: #F4B767;
    position: absolute;
    top: 0;
    left: 0;
    border-radius:50%;
    z-index:-1;
    animation:1s circle10 infinite;
}
.circle5{
    width: 30px;
    height:30px;
    background: #F4B767;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius:50%;
    z-index:-1;
    animation:1s circle10 infinite;
}
.circlegreen5{
    width: 24px;
    height:24px;
    background: lime;
    position: absolute;
    top: 50px;
    right: 150px;
    border-radius:50%;
    z-index:-1;
    animation:1s circle10 infinite;
}
.circleblue5{
    width: 24px;
    height:24px;
    background: #8C7AFF;
    position: absolute;
    bottom: 50px;
    left: 0;
    border-radius:50%;
    z-index:1;
    animation:1s circle10 infinite;
}
.circleorange5{
    width: 24px;
    height:24px;
    background: #F4B767;
    position: absolute;
    bottom: 100px;
    right: 0px;
    border-radius:50%;
    z-index:1;
    animation:1s circle10 infinite;
}
.circleindigo{
    width: 150px;
    height:150px;
    background: #687EF3;
    position: absolute;
    top: -50px;
    left:0;
    border-radius:50%;
    z-index:-1;
    animation:1s circle10 infinite;
}

.square138{
    width: 138px;
    height:138px;
    background: #23BDEE;
    position: absolute;
    top: -20px;
    left: -20px;
    border-radius:20px;
    z-index: -1;
    animation:1s rotate infinite;
}

.square231{
    width: 231px;
    height:231px;
    background: #F3AC50;
    position: absolute;
    bottom: -20px;
    right: -20px;
    border-radius:20px;
    z-index: -1;
    animation:1s rotate infinite;
}
img.teacher{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius:20px;
    z-index: -1;
}

section .caller{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height:400px;
    position: relative;
}

.caller  img{
    width:100%;
    position: absolute;
    top: 0;
    left: 0;
}
.caller  .network{
    background: #c9d3e788;
    position: absolute;
    bottom: 5px;
    left: 5px;
    color: white;
    max-width: 170px;
    display: flex;
    justify-content: space-around;
}

.caller button.present{
    width: 153px;
    height: 50px;
    border-radius: 25px;
    background: #3465E1;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22.1293px;
    line-height: 30px;
    color: #FFFFFF;
    box-shadow: 0px 8.51126px 20.427px rgba(52, 101, 225, 0.3);
    border:0px solid white;
}

.caller button.calling{
    width: 153px;
    height: 50px;
    border-radius: 25px;
    background: #E13468;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22.1293px;
    line-height: 30px;
    color: #FFFFFF;
    box-shadow: 0px 8.51126px 20.427px rgba(52, 101, 225, 0.3);
    border:0px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
}


.caller .phone{
    display: flex;
    justify-content: space-between;
    align-items:center;
    
}

.question{
    display: inline-block;
    width: 80%;
    height: 562.39px;
    background: #FFFFFF;
    box-shadow: 0px 9.10294px 43.6941px rgba(40, 43, 111, 0.1);
    border-radius: 18.2059px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 60px 25px;
    align-items: flex-start;
    position: relative;
}

.question .icon_false{
    width:53px;
    height:53px;
    position:absolute;
    top: -21.5px;
    right:21.5px;
    cursor:pointer;
    transform:rotate(-10.96deg);
}
.question .icon_true{
    width:53px;
    height:53px;
    position:absolute;
    top: 21.5px;
    right:-21.5px;
    cursor:pointer;
    transform:rotate(13.9deg);
}

.question p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30.95px;
    line-height: 160%;
    color: #55578D;
    text-align: start;
}

.question .number_question{
    width: 174.37px;
    height: 47.9px;
    background: #CDD8FF;
    border-radius: 23.95px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20.0265px;
    line-height: 160%;
    color: #717FB0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.question img{
    border-radius:18px;
    width: 100%;
}

.question .sended{
    background: #FFFFFF;
    box-shadow: 0px 18.2059px 52.7971px rgba(40, 43, 111, 0.08);
    border-radius: 18.2059px;
    position: absolute;
    bottom: -20px;
    right: -20px;
    width: 365px;
    height: 119px;
    padding: 15px;
}

p.send_msg{
    color: #41BE90;
    font-size: 14px;
    margin: 0;
}
.sendicon{
    color: #41BE90;
    margin: 0;  
    font-size: 50px;
}

.gradebook{
    width: 100%;
    min-height:470px;
    background: #FFFFFF;
    box-shadow: 0px 10px 48px rgba(40, 43, 111, 0.1);
    position: relative;
    display: flex;
    flex-direction:column;
    margin-top:100px;
}

.gradetop{
    width:100%;
    flex:0.15;
    background:  #54AFF0;
    display: flex;
    justify-content: center;
    align-items:center;
    color:white;
    font-weight: 700;
    font-size: 28px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.grademain{
    width: 100%;
    flex:0.85;
    background: white;
    position: relative;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.grademain .rightman{
    width: 40%;
    position: absolute;
    left:0;
    top: 20px;
}

.grademain .rightman .lenta{
    flex:1;
    height: 20px;
    background: lime;
}

.grademain .rightman img{
    width:85px;
    height:85px;
    border-radius:50%;
}
.grademain .rightman2{
    width: 60%;
    position: absolute;
    left:0;
    top: 150px;
}

.grademain .rightman2 .lenta{
    flex:1;
    height: 20px;
    background: rgb(230, 13, 13);
}

.grademain .rightman2 img{
    width:85px;
    height:85px;
    border-radius:50%;
}



.grademain .leftman{
    width: 50%;
    position: absolute;
    right:0;
    top: 60px;
}

.grademain .leftman .lenta{
    flex:1;
    height: 20px;
    background: indigo;
}

.grademain .leftman img{
    width:85px;
    height:85px;
    border-radius:50%;
}
.grademain .leftman2{
    width: 30%;
    position: absolute;
    right:0;
    top: 180px;
}

.grademain .leftman2 .lenta{
    flex:1;
    height: 20px;
    background: blue;
}

.grademain .leftman2 img{
    width:85px;
    height:85px;
    border-radius:50%;
}

.gradebook .star-round{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(-45deg);
    position: absolute;
    top: -40px;
    left:-40px;
    border-radius: 50%;
    z-index: 1;
    background: white;
    border: 10px solid rgba(221, 216, 216, 0.123);
}

.score{
    width: 80px;
    height:50px;
    background: #CDF3FF;
    border-radius: 25px;
    position: absolute;
    bottom: -20px;
    left: -40px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 160%;
    color: #43869B;
    display: flex;
    justify-content: center;
    align-items: center;
}
.score-lime{
    width: 80px;
    height:50px;
    background: #D7FFCD;
    border-radius: 25px;
    position: absolute;
    bottom: -20px;
    right: -40px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 160%;
    color: #43869B;
    display: flex;
    justify-content: center;
    align-items: center;
}
.score-red{
    width: 80px;
    height:50px;
    background: pink;
    border-radius: 25px;
    position: absolute;
    bottom: -20px;
    right: -40px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 160%;
    color: #43869B;
    display: flex;
    justify-content: center;
    align-items: center;
}


.star-round .big_star_icon{
    color: #F9BB1C;
    font-size: 50px;
}

button.export{
    width: 169.93px;
    height: 57.9px;
    background: linear-gradient(105.5deg, #545AE7 19.57%, #393FCF 78.85%);
    border-radius: 28.95px;
    position: absolute;
    right:50px  ;
    bottom: 20px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #FFFFFF;
}

button.see-more-feature{
    width: 280px;
    height: 80px;
    border: 1px solid #F48C06;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 180%;
    letter-spacing: 0.02em;
    color: #F48C06;
    background: rgba(0, 0, 0, 0);
}
button.assessment{
    width: 403px;
    height: 80px;
    border: 1px solid #F48C06;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 180%;
    letter-spacing: 0.02em;
    color: #F48C06;
    background: rgba(0, 0, 0, 0);
    position: relative;
}
.assessment .arrow-icon{
    width:80px;
    height:80px;
    border: 1px solid #F48C06;
    color: #F48C06;
    position: absolute;
    border-radius:40px;
    top: 0;
    right: -1px;
}


.line{
    width: 80px;
    height: 1px;
    background: #525596;
    margin-right:20px;
}
.INTEGRATIONS{
    margin-top:50px;
}

h6{
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.2em;
    color: #525596;
}
.toolpat{
    width: 636px;
    height:700px;
    position: relative;
}

.gloria{
    width:460px;
    height: 329px;
    position: absolute;
    bottom: -10px;
    right:0px;
    background: #FFFFFF;
    box-shadow: 2px 4px 60px rgba(41, 44, 124, 0.1);
    border-radius: 20px;
    display: flex;
    overflow: hidden;
}
.gloria .gloria-text{
    width: 95%;
    height: 100%;
}
.gloria-text p{
    font-family: 'Nunito Sans', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 22px;
line-height: 180%;
letter-spacing: 0.02em;
padding: 10px 0px 10px 20px;
}
p.gloria-name{
    font-family: 'Nunito Sans', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 180%;
/* or 43px */


color: #5F5F7E;
}
.gloria .gloria-left{
    width:5%;
    height: 100%;
    background: #f67766;
}

section h5{
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 180%;
    color: #2F327D;
    text-align: center;
}

button.news{
    width: 130px;
    height: 40px;
    background: #F4C467;
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: 0.02em;
    color: #252641;
}

img.big-news{
    width: 100%;
    height: 340px;
    border-radius: 20px;
}

h6.end26{
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 26px;
line-height: 180%;
/* or 47px */

text-align: start;
color: #252641;
}
h6.end22{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
    text-align: start;
    color: #252641;
}
.new-text{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: space-between;
    padding: 10px 15px;
    flex: 1;
}

.news-card{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}

.news-card .new-img{
    width: 280px;
    height: 200px;
    position: relative;
    border-radius: 20px;
    overflow:hidden;
}

.news-card .new-img img{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
}

.news-card .new-img button{
    position: absolute;
    bottom:20px;
    right: 20px;
}
.teacher-blok{
    height: 350px;
}
li{
    list-style-type: none;
}

.carousell{
    width: 100%;
    overflow: hidden;
}



@media(max-width:460px){
    section h3.w500 {
        font-size: 24px;
    }
    section h3.w600 {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 140%;
    }
}

@media(max-width:576px){
    .question{
        width: 100%;
    }

    button.assessment{
        width: 300px;
        height: 60px;
        border: 1px solid #F48C06;
        box-sizing: border-box;
        border-radius: 30px;
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
    }

    .assessment .arrow-icon{
        width:60px;
        height:60px;
        border-radius: 30px;
    }

    button.see-more-feature{
        width: 210px;
        height: 60px;
        border-radius: 30px;
        font-size: 16px;
    }
}

@media(max-width:767px){
    .gloria{
        width:360px;
        height: 249px;
        position: absolute;
        bottom: 0px;
        right:270px;
        padding-bottom:20px;
        padding-right: 15px;
    }
    .gloria-text p{
        font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    }
    p.gloria-name{
        font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 180%;
    color: #5F5F7E;
    }
    .news-card{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }
}

@media(max-width:400px){
    .gloria{
        width:300px;
        height: 280px;
        position: absolute;
        bottom: 0px;
        right:310px;
        padding-right: 10px;
    }

    h6.end26{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 180%;
        text-align: start;
        color: #252641;
    }
}



@media(max-width:1199px){
    section .sec_blok {
        width: 100%;
        min-height: 430px;
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 10px 60px rgba(38, 45, 118, 0.08);
        border-radius: 20px;
        padding: 5px 0px;
        margin-top:150px;
        transition:0.5s;
    }
    .news-card{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 20px 0;
    }
}
@media(min-width:992px){
    section .sec_blok {
        width: 100%;
        min-height: 430px;
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 10px 60px rgba(38, 45, 118, 0.08);
        border-radius: 20px;
        padding: 5px 0px;
        margin-top:150px;
        transition:0.5s;
    }
}

@media(max-width:991px){
    section .sec_blok {
        padding: 15px 15px 60px 15px;
    }
}
@media(max-width:400px){
    section .sec_blok {
        padding: 15px  15px;
    }
}

@media(max-width:650px){
    .square138{
        width: 138px;
        height:138px;
        background: #23BDEE;
        position: absolute;
        top: -20px;
        left: 0px;
        border-radius:20px;
        z-index: -1;
        animation:none;
    }
    
    .square231{
        width: 231px;
        height:231px;
        background: #F3AC50;
        position: absolute;
        bottom: -20px;
        right: 0px;
        border-radius:20px;
        z-index: -1;
        animation:none;
    }
    .question .icon_true{
        width:53px;
        height:53px;
        position:absolute;
        top: 21.5px;
        right:0px;
        cursor:pointer;
        transform:rotate(13.9deg);
    }
    .question .sended{
        background: #FFFFFF;
        box-shadow: 0px 18.2059px 52.7971px rgba(40, 43, 111, 0.08);
        border-radius: 18.2059px;
        position: absolute;
        bottom: -20px;
        right: 0px;
        width: 365px;
        height: 119px;
        padding: 15px;
    }
}


.phone_icon {
    position: fixed;
    bottom: 30px;
    left: 30px;
    animation: ring 4s 0.7s ease-in-out infinite;
    z-index: 1;
    cursor: pointer;
  }


@keyframes ring {
    0% {
      transform: rotate(0);
    }
    5% {
      transform: rotate(30deg);
    }
    10% {
      transform: rotate(-28deg);
    }
    15% {
      transform: rotate(34deg);
    }
    20% {
      transform: rotate(-32deg);
    }
    25% {
      transform: rotate(30deg);
    }
    30% {
      transform: rotate(-28deg);
    }
    35% {
      transform: rotate(26deg);
    }
    40% {
      transform: rotate(-24deg);
    }
    45% {
      transform: rotate(22deg);
    }
    50% {
      transform: rotate(-20deg);
    }
    55% {
      transform: rotate(18deg);
    }
    60% {
      transform: rotate(-16deg);
    }
    65% {
      transform: rotate(14deg);
    }
    70% {
      transform: rotate(-12deg);
    }
    75% {
      transform: rotate(10deg);
    }
    80% {
      transform: rotate(-8deg);
    }
    85% {
      transform: rotate(6deg);
    }
    90% {
      transform: rotate(-4deg);
    }
    95% {
      transform: rotate(2deg);
    }
    100% {
      transform: rotate(-1deg);
    }
  }